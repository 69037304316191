<template>
	<div>
		<transition :name="routerTransition" mode="out-in">
			<keep-alive>
				<router-view v-if="$store.state.utilities.seasons.length > 0"/>
			</keep-alive>
		</transition>
	</div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig.js";
export default {
	setup() {
		const { routerTransition } = useAppConfig();
		return {
			routerTransition,
		};
	},
};
</script>
